@import 'two-app-ui/build/esm/src/scss/theme/_variables';

#layout-wrapper{
  background-color: $shade300;
}

#app_toolbar{
  flex: 0 1 auto;
}

#content{
  width: 100%;
  max-width: 768px;
  flex: 1 1 auto;
}

.p-dropdown-panel{
  max-width: 100%;
  .p-dropdown-item{
    white-space: pre-wrap;
  }
}
