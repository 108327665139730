.draft-card {
  background-color: #fff;
  .p-card-body {
    padding: 0;
    .p-card-content {
      padding: 1rem;
      .x-small-label {
        font-size: xx-small;
      }
    }
  }
}
