#new_edit_draft_form{
  height: 100%;
  overflow: hidden;
  .draft-content {
    height: 100%;
    .draft-scroll-panel {
      height: 100%;
      min-width: 100%;
      .p-scrollpanel-bar-y {
        width: 0;
      }
    }
  }
  .draft-buttons {
    max-width: 768px;
    position: absolute;
    bottom: 0;
  }
  .toggle-items-button {
    width: 4rem;
    height: 4rem;
    position: absolute;
    right: 2rem;
    bottom: 3.5rem;
    z-index: 10;
    background-color: #3B82F6;
    color: white;
  }
}
