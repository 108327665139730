#drafts_page {
  height: 100%;
  overflow: hidden;
  .drafts-content{
    height: 100%;
    .drafts-search {
      min-width: 100%;
    }
    .drafts-scroll-panel {
      height: calc(100% - 1rem - 2px - 1.4rem - 1rem);
      min-width: 100%;
      .p-scrollpanel-bar-y {
        width: 0;
      }
    }
  }
  .add-draft-button {
    width: 4rem;
    height: 4rem;
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    z-index: 10;
    background-color: #3B82F6;
    color: white;
  }
}


